import React, { useState } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import {
  ArrowBack,
  ArrowForward,
  Circle,
  CircleOutlined,
} from "@mui/icons-material";
import { Box, Fab, Paper } from "@mui/material";

const ImageCarousel = ({ images }) => {
  const ref = React.useRef();
  const [centerSlideDataIndex, setCenterSlideDataIndex] = useState(0);
  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          let currentVisibleSlide = 5;
          if (parentWidth <= 1400) currentVisibleSlide = 3;
          if (parentWidth <= 1000) currentVisibleSlide = 1;
          return (
            <>
              <StackedCarousel
                ref={carouselRef}
                slideComponent={Card}
                slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
                carouselWidth={parentWidth}
                data={images}
                currentVisibleSlide={currentVisibleSlide}
                maxVisibleSlide={5}
                useGrabCursor
                onActiveSlideChange={(newIndex) =>
                  setCenterSlideDataIndex(newIndex)
                }
              />
              {
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 2,
                  }}
                >
                  {images?.map((img, index) => {
                    return centerSlideDataIndex === index ? (
                      <Circle key={index} color="primary" />
                    ) : (
                      <CircleOutlined key={index} color="primary" />
                    );
                  })}
                </Box>
              }
              <>
                <Fab
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: 20,
                    zIndex: 10,
                  }}
                  size="small"
                  color="primary"
                  onClick={() => {
                    ref.current?.goBack();
                  }}
                >
                  <ArrowBack />
                </Fab>
                <Fab
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: 20,
                    zIndex: 10,
                  }}
                  size="small"
                  color="primary"
                  onClick={() => {
                    ref.current?.goNext();
                  }}
                >
                  <ArrowForward />
                </Fab>
              </>
            </>
          );
        }}
      />
    </div>
  );
};

export const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { imgPath, label } = data[dataIndex];
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "70vh",
          userSelect: "none",
        }}
        className="my-slide-component"
      >
        <img
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: 10,
          }}
          draggable={false}
          src={imgPath}
          alt={label}
        />
        <Paper
          style={{
            position: "absolute",
            bottom: 20,
            left: 20,
            zIndex: 10,
            borderRadius: 5,
            padding: 10,
          }}
        >
          {label}
        </Paper>
      </div>
    </>
  );
});

export default ImageCarousel;
