import React, { Fragment, useState } from "react";
import { ExpandMore, InfoTwoTone } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";

const handleShowInfo = (obj) => {
  return Object.keys(obj).map((key) => {
    const condition = typeof obj[key] !== "object";
    return (
      <Fragment key={key}>
        {condition ? (
          <Paper elevation={0}>
            <Typography p={2}>
              <strong>{key}: </strong>
              {obj[key].toString()}
            </Typography>
          </Paper>
        ) : (
          <Accordion elevation={1}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <strong>{key}: </strong>
            </AccordionSummary>
            <AccordionDetails>{handleShowInfo(obj[key])}</AccordionDetails>
          </Accordion>
        )}
      </Fragment>
    );
  });
};

const InfoCellModal = ({ myObject, title }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        color="secondary"
        onClick={() => {
          handleClickOpen();
        }}
      >
        <InfoTwoTone color="secondary" />
      </IconButton>
      {open && (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          scroll="paper"
          aria-labelledby="new-tour-title"
          aria-describedby="new-tour-description"
        >
          <DialogTitle id="new-tour-title">{title}</DialogTitle>
          <DialogContent dividers>{handleShowInfo(myObject)}</DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default InfoCellModal;
