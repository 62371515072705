import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BlankPage from "../components/BlankPage";
import StepsContainer from "../components/donate/StepsContainer";
import { Box, CardMedia, Container, Typography } from "@mui/material";
import { Favorite } from "@mui/icons-material";
import { PayPal } from "../utils/publicImport";

const Donate = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Donate</title>
        <meta
          name="description"
          content="Please donate, so we will continue make guided tours in th holy land of israel, to share with you."
        />
      </Helmet>
      <BlankPage>
        <Container maxWidth="md">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: { sm: "wrap" },
              mb: 5,
              mt: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              textAlign="center"
              sx={{
                fontWeight: "700",
                fontSize: { xs: "2em", md: "3em" },
                textTransform: "capitalize",
              }}
            >
              Donate <Favorite fontSize="large" color="error" />
            </Typography>
            <Box sx={{ flex: "1 1 auto" }} />
            <CardMedia
              component="img"
              src={PayPal}
              alt="PayPal and credit cards"
              sx={{
                maxWidth: 200,
                marginX: "auto",
                marginY: 2,
              }}
            />
          </Box>

          <StepsContainer />
        </Container>
      </BlankPage>
    </>
  );
};

export default Donate;
