import React from "react";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import { ROUTES } from "../../utils/contants";
import { useNavigate } from "react-router";
import ThreeSampleVideos from "./ThreeSampleVideos";
// import { useSelector } from "react-redux";
// import { selectVerifyByEmailState } from "../../redux/user/verifyByEmailSlice";
// import { selectVerifyJWTState } from "../../redux/user/verifyJWTSlice";

const SectionOne = () => {
  const navigate = useNavigate();
  // const { verifyByEmailUser } = useSelector(selectVerifyByEmailState);
  // const { verifyJWTUser } = useSelector(selectVerifyJWTState);

  return (
    <Paper
      elevation={0}
      sx={{
        background:
          "linear-gradient(180deg, rgba(255,255,255,1) 25%, rgba(0,171,238,1) 100%)",
        minHeight: "100vh",
        borderRadius: 0,
      }}
    >
      <Container
        maxWidth="md"
        sx={{ paddingTop: "64px", textAlign: "center", pb: 5 }}
      >
        <Box sx={{ minHeight: 100 }} />
        <Typography
          textAlign="center"
          mb={5}
          sx={{
            fontWeight: "900",
            fontSize: { xs: "2.5em", md: "5em" },
            textTransform: "capitalize",
          }}
        >
          Want to travel Israel?
        </Typography>

        <Typography
          variant="h4"
          textAlign="center"
          mb={5}
          sx={{
            fontWeight: "light",
            fontSize: { xs: "1.3em", md: "2em" },
          }}
        >
          Live From Israel offers online guided tours pre-recorded in HD quality
          of Israel's most popular tourist sites.
        </Typography>
        <Box mb={5} sx={{ flexGrow: 1 }}>
          <Button
            variant="contained"
            size="large"
            sx={{
              m: 2,
              minWidth: 200,
              textTransform: "none",
              justifyContent: "center",
            }}
            onClick={() => navigate(ROUTES.USER)}
          >
            <Typography
              variant="p"
              textAlign="center"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "1.3em", md: "2em" },
              }}
            >
              Watch Now
            </Typography>
            <Box width={15} />
            <Typography
              variant="p"
              textAlign="center"
              sx={{
                fontWeight: "light",
                fontSize: { xs: "1.3em", md: "2em" },
              }}
            >
              It's free
            </Typography>
          </Button>
        </Box>
      </Container>
      <ThreeSampleVideos />
    </Paper>
  );
};

export default SectionOne;
