import React from "react";
import {
  Box,
  Button,
  CardMedia,
  Container,
  Paper,
  Typography,
} from "@mui/material";
import { Facebook, Favorite, Instagram, Mail } from "@mui/icons-material";
import { logo } from "../utils/publicImport";
import { LINKS } from "../utils/contants";

const Footer = () => {
  return (
    <Paper elevation={0} sx={{ background: "#282828", borderRadius: 0 }}>
      <Container maxWidth="md">
        <Box sx={{ minHeight: 50 }} />
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexFlow: { xs: "wrap", sm: "nowrap" },
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Box flexGrow={1} mt={5}>
            <CardMedia
              component="img"
              src={logo}
              alt="Devices Live From Israel"
              sx={{
                maxWidth: { xs: 50, md: 100 },
                marginX: "auto",
              }}
            />
            <Typography
              textAlign="center"
              sx={{
                fontWeight: "900",
                fontSize: { xs: "1.5em", md: "3em" },
                textTransform: "capitalize",
                color: "white",
              }}
            >
              live from israel
            </Typography>
          </Box>
        </Box>
        <Box textAlign="center" my={10}>
          <Typography
            variant="h4"
            textAlign="center"
            color="white"
            my={2}
            sx={{ fontWeight: "light", fontSize: { xs: "1.3em", md: "2em" } }}
          >
            Would <Favorite color="error" /> To Hear From You.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              flexFlow: { xs: "wrap", md: "nowrap" },
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              size="large"
              color="info"
              fullWidth
              sx={{
                m: 2,
                minWidth: 100,
                minHeight: 100,
                textTransform: "none",
              }}
              href={LINKS.FACEBOOK}
              target={LINKS.NEW_TAB}
            >
              <Facebook sx={{ marginRight: 1 }} />
              Live From Israel
            </Button>
            <Button
              variant="contained"
              size="large"
              color="light"
              fullWidth
              sx={{
                m: 2,
                minWidth: 100,
                minHeight: 100,
                textTransform: "none",
              }}
              href={LINKS.INSTAGRAM}
              target={LINKS.NEW_TAB}
            >
              <Instagram sx={{ marginRight: 1 }} />
              @livefromisrael
            </Button>
            <Button
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              sx={{
                m: 2,
                minWidth: 100,
                minHeight: 100,
                textTransform: "none",
              }}
              href={LINKS.EMAIL}
              target={LINKS.NEW_TAB}
            >
              <Mail sx={{ marginRight: 1 }} />
              livefromisrael@gmail.com
            </Button>
          </Box>
        </Box>
        <Typography
          variant="h4"
          textAlign="center"
          color="white"
          sx={{ fontWeight: "light", fontSize: { xs: "0.5em", md: "1em" } }}
        >
          All Rights Reserved
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          color="white"
          sx={{ fontWeight: "light", fontSize: { xs: "0.5em", md: "1em" } }}
        >
          2022 © Live From Israel
        </Typography>

        <Box sx={{ minHeight: 50 }} />
      </Container>
    </Paper>
  );
};

export default Footer;
