import React, { useEffect } from "react";
import BlankPage from "../components/BlankPage";
import Lottie from "lottie-react";
import NotFoundLottie from "../assets/lottie/notfound.json";
import { Box, Container, Typography } from "@mui/material";

const NotFound = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <BlankPage>
        <Container maxWidth="xs" sx={{ marginTop: 10 }}>
          <Box my={5}>
            <Typography
              textAlign="center"
              sx={{
                fontWeight: "900",
                fontSize: { xs: "2em", md: "3em" },
                textTransform: "capitalize",
              }}
            >
              Opps!
            </Typography>
            <Typography
              textAlign="center"
              sx={{
                fontWeight: "light",
                fontSize: { xs: "1em", md: "2em" },
              }}
            >
              Page doesn't exist.
            </Typography>
          </Box>

          <Lottie animationData={NotFoundLottie} />
        </Container>
      </BlankPage>
    </>
  );
};

export default NotFound;
