import React, { useEffect } from "react";
import DataTable from "./DataTable";
import { Alert, AlertTitle, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getJWTFromLocalStorage } from "../../utils/functions";
import { STATUS } from "../../utils/contants";
import {
  getAllDonations,
  selectAdminAllDonationsState,
} from "../../redux/donation/adminAllDonationsSlice";

const Donations = () => {
  const dispatch = useDispatch();

  const {
    adminAllDonationsArray,
    adminAllDonationsStatus,
    adminAllDonationsError,
  } = useSelector(selectAdminAllDonationsState);

  useEffect(() => {
    const token = getJWTFromLocalStorage();
    if (adminAllDonationsStatus === STATUS.IDLE && token) {
      dispatch(getAllDonations(token));
    }
  }, [dispatch, adminAllDonationsStatus]);

  return (
    <Box my={5}>
      <h2>Donations</h2>
      {adminAllDonationsError && (
        <Alert severity="error" sx={{ my: 2 }}>
          <AlertTitle>{adminAllDonationsError}</AlertTitle>
        </Alert>
      )}
      <DataTable
        title={"Donations"}
        data={adminAllDonationsArray}
        showEdit={false}
        showDelete={false}
      />
    </Box>
  );
};

export default Donations;
