import React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ROUTES, STATUS } from "../../utils/contants";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { authLogin, selectLoginState } from "../../redux/user/loginSlice";

const EmailPassword = () => {
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const navigate = useNavigate();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  const { loginStatus, loginError } = useSelector(selectLoginState);

  return (
    <Box
      display="flex"
      flexGrow={1}
      textAlign="center"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      flexWrap="wrap"
    >
      {loginError && (
        <Alert severity="error" sx={{ my: 2 }}>
          <AlertTitle>{loginError}</AlertTitle>
        </Alert>
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const userInfo = {
            email: values.email,
            password: values.password,
          };
          dispatch(authLogin(userInfo));
        }}
      >
        <FormControl fullWidth margin="normal" variant="outlined" required>
          <InputLabel htmlFor="outlined-email">Email</InputLabel>
          <OutlinedInput
            id="outlined-email"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            label="Email"
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined" required>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <Button
          variant="contained"
          type="submit"
          sx={{ textTransform: "capitalize", my: 5 }}
        >
          {loginStatus === STATUS.LOADING ? (
            <CircularProgress color="inherit" />
          ) : (
            "Submit"
          )}
        </Button>
      </form>

      <Typography variant="body2">
        Don't have a user?
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => navigate(ROUTES.REGISTER)}
        >
          Sign Up
        </Button>
      </Typography>
      <Typography variant="body2">
        Forgot your password?
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => navigate(ROUTES.RESET_PASSWORD.STEP_ONE)}
        >
          Reset password
        </Button>
      </Typography>
    </Box>
  );
};

export default EmailPassword;
