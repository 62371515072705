import { configureStore } from "@reduxjs/toolkit";
import donateModalReducer from "./donateModal/donateModalSlice";
import loginReducer from "./user/loginSlice";
import registerReducer from "./user/registerSlice";
import verifyByEmailReducer from "./user/verifyByEmailSlice";
import verifyJWTReducer from "./user/verifyJWTSlice";
import verifyJWTAdminReducer from "./user/verifyJWTAdminSlice";
import adminAllUsersReducer from "./user/adminAllUsersSlice";
import allToursReducer from "./tour/allToursSlice";
import adminAllDonationsReducer from "./donation/adminAllDonationsSlice";
import passwordResetReducer from "./passwordReset/passwordResetSlice";

export const store = configureStore({
  reducer: {
    donateModal: donateModalReducer,
    login: loginReducer,
    register: registerReducer,
    verifyByEmail: verifyByEmailReducer,
    verifyJWT: verifyJWTReducer,
    verifyJWTAdmin: verifyJWTAdminReducer,
    adminAllUsers: adminAllUsersReducer,
    allTours: allToursReducer,
    adminAllDonations: adminAllDonationsReducer,
    passwordReset: passwordResetReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: false,
});
