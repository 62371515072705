import React from "react";
import { Box, CardMedia, Container, Paper, Typography } from "@mui/material";
import Lottie from "lottie-react";
import Videos from "../../assets/lottie/videos.json";
import { dashboardImage } from "../../utils/publicImport";

const SectionFive = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        background: "white",
        minHeight: "100vh",
        marginBottom: 15,
      }}
    >
      <Container sx={{ paddingTop: "64px", textAlign: "center" }}>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexFlow: { xs: "wrap", md: "nowrap" },
            textAlign: "center",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Box flexGrow={1}>
            <Lottie animationData={Videos} style={{ height: 200 }} />
          </Box>
          <Box flexGrow={1}>
            <Typography
              textAlign="center"
              my={0}
              sx={{
                fontWeight: "900",
                fontSize: { xs: "2.5em", md: "5em" },
                textTransform: "capitalize",
              }}
            >
              All in the dashboard.
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="h4"
          textAlign="center"
          mb={5}
          sx={{
            fontWeight: "light",
            fontSize: { xs: "1.3em", md: "2em" },
            marginX: "auto",
          }}
        >
          All of our tours are available anytime and anywhere on our websites
          dashboard.
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{
            fontWeight: "light",
            fontSize: { xs: "1.3em", md: "2em" },
            marginX: "auto",
          }}
        >
          We update our tours constantly, and we try to be as diversified as
          possible. Allowing you to tour all the amazing touring sites Israel
          has to offer.
        </Typography>
        {/*<img src={dashboardImage} />*/}
        <CardMedia
          component="img"
          src={dashboardImage}
          alt="dashboard image live from israel"
          sx={{
            marginX: "auto",
          }}
        />
      </Container>
    </Paper>
  );
};

export default SectionFive;
