import React, { useEffect } from "react";
import BlankPage from "../components/BlankPage";
import Tours from "../components/admin/Tours";
import Donations from "../components/admin/Donations";
import Users from "../components/admin/Users";
import { ROUTES } from "../utils/contants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getJWTFromLocalStorage } from "../utils/functions";
import { selectVerifyByEmailState } from "../redux/user/verifyByEmailSlice";
import { selectVerifyJWTState } from "../redux/user/verifyJWTSlice";
import { selectVerifyJWTAdminState } from "../redux/user/verifyJWTAdminSlice";

const Admin = () => {
  const navigate = useNavigate();

  const { verifyByEmailUser } = useSelector(selectVerifyByEmailState);
  const { verifyJWTUser } = useSelector(selectVerifyJWTState);
  const { verifyJWTAdminUser } = useSelector(selectVerifyJWTAdminState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const token = getJWTFromLocalStorage();
    if (!token) {
      navigate(ROUTES.USER);
    }
    if (!(verifyJWTUser || verifyByEmailUser)) {
      navigate(ROUTES.USER);
    }
    if (!verifyJWTAdminUser) {
      navigate(ROUTES.USER);
    }
  }, [navigate, verifyJWTUser, verifyByEmailUser, verifyJWTAdminUser]);

  return (
    <>
      <BlankPage>
        <Tours />
        <Donations />
        <Users />
      </BlankPage>
    </>
  );
};

export default Admin;
