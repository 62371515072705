import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import axios from "axios";
import { ROUTES, SERVER_ROUTES } from "../../utils/contants";
import VideoWithDescription from "../dashboard/VideoWithDescription";
import { getJWTFromLocalStorage } from "../../utils/functions";
import { useNavigate } from "react-router";

const SERVER = process.env.REACT_APP_SERVER_URL;

const postNewTour = async (data, token) => {
  try {
    await axios.post(SERVER + SERVER_ROUTES.TOURS.CREATE, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    console.log(e);
  }
};

const NewTourModal = () => {
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({
    location: "",
    date: "",
    description: "",
    url: "",
    img: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    const token = getJWTFromLocalStorage();
    if (!token) {
      setOpen(false);
      setValues({ location: "", date: "", description: "", url: "", img: "" });
      navigate(ROUTES.HOME);
      return;
    }
    postNewTour(values, token);
    setOpen(false);
    setValues({ location: "", date: "", description: "", url: "", img: "" });
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        onClick={handleClickOpen}
        sx={{ textTransform: "capitalize", my: 2 }}
      >
        <AddCircleOutline /> &ensp; New Tour
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        scroll="paper"
        aria-labelledby="new-tour-title"
        aria-describedby="new-tour-description"
      >
        <DialogTitle id="new-tour-title">New Tour</DialogTitle>
        <DialogContent dividers>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="outlined-location">location</InputLabel>
              <OutlinedInput
                id="outlined-location"
                type="text"
                value={values.location}
                onChange={handleChange("location")}
                label="location"
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <OutlinedInput
                id="outlined-date"
                type="date"
                value={values.date}
                onChange={handleChange("date")}
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="outlined-description">
                description
              </InputLabel>
              <OutlinedInput
                id="outlined-description"
                type="text"
                value={values.description}
                onChange={handleChange("description")}
                label="description"
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="outlined-url">url</InputLabel>
              <OutlinedInput
                id="outlined-url"
                type="text"
                value={values.url}
                onChange={handleChange("url")}
                label="url"
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="outlined-img">img</InputLabel>
              <OutlinedInput
                id="outlined-img"
                type="text"
                value={values.img}
                onChange={handleChange("img")}
                label="img"
              />
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: "capitalize", my: 5 }}
            >
              Submit
            </Button>
          </form>
          <VideoWithDescription
            location={values.location}
            description={values.description}
            url={values.url}
            img={values.img}
            date={values.date}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NewTourModal;
