import React from "react";
import { Box, CardMedia, Container, Paper, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { Devices } from "../../utils/publicImport";
import WIFI from "../../assets/lottie/wifi.json";

const SectionTwo = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        background:
          "linear-gradient(180deg, rgba(0,171,238,1) 0%, rgba(0,84,166,1) 75%)",
        minHeight: "100vh",
        borderRadius: 5,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ minHeight: 120 }} />
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexFlow: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <Typography
            textAlign="center"
            my={0}
            color="white"
            sx={{
              fontWeight: "900",
              fontSize: { xs: "2.5em", md: "5em" },
              display: "inline-flex",
              textAlign: "center",
              textTransform: "capitalize",
            }}
          >
            All you need is
          </Typography>

          <Lottie
            animationData={WIFI}
            style={{ height: 200, display: "inline-flex" }}
          />
        </Box>

        <Typography
          variant="h4"
          textAlign="center"
          color="white"
          mb={5}
          sx={{ fontWeight: "light", fontSize: { xs: "1.3em", md: "2em" } }}
        >
          Watch our guided tours from anywhere, on any device. All our tours are
          available online worldwide, for FREE.
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          color="white"
          mb={5}
          sx={{ fontWeight: "light", fontSize: { xs: "1.3em", md: "2em" } }}
        >
          The tours are streamed at a maximum quality of 1080p HD. As long as
          you have a good internet connection, you can enjoy our guided tours.
        </Typography>

        <CardMedia
          component="img"
          src={Devices}
          alt="Devices Live From Israel"
          sx={{
            maxWidth: 500,
            marginX: "auto",
          }}
        />
        <Box sx={{ minHeight: 50 }} />
      </Container>
    </Paper>
  );
};

export default SectionTwo;
