import React from "react";
import { Button, CardMedia, Container, Paper, Typography } from "@mui/material";

import { amber } from "@mui/material/colors";
import { PayPal } from "../../utils/publicImport";
import { ROUTES } from "../../utils/contants";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { close } from "../../redux/donateModal/donateModalSlice";
import { TouchApp } from "@mui/icons-material";

const DonationModalContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Paper
      elevation={0}
      sx={{
        background: amber[400],
        borderRadius: 0,
        pt: 2,
      }}
    >
      <Container maxWidth="md" sx={{ textAlign: "center" }}>
        <Typography
          textAlign="center"
          sx={{
            fontWeight: "900",
            fontSize: { xs: "2em", md: "4.5em" },
            pb: 2,
          }}
        >
          Please. Donate.
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{ fontWeight: "light", fontSize: { xs: "1.3em", md: "2em" } }}
        >
          We love doing guided tours, and we love sharing our fantastic work
          with you. Your donation helps us keep going, and creating more and
          more tours throughout the Holy Land of Israel.
        </Typography>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          fullWidth
          sx={{
            my: 5,
            minWidth: 200,
            textTransform: "capitalize",
            height: 60,
            fontSize: "1.3em",
          }}
          onClick={() => {
            dispatch(close());
            navigate(ROUTES.DONATE);
          }}
        >
          Donate <TouchApp />
        </Button>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{ fontWeight: "light", fontSize: { xs: "1.3em", md: "2em" } }}
        >
          You can donate with Credit-Card (all brands), and PayPal. The payment
          the process is secure, and being handled by PayPal directly.
        </Typography>
        <CardMedia
          component="img"
          src={PayPal}
          alt="PayPal and credit cards"
          sx={{
            maxWidth: 200,
            marginX: "auto",
            marginY: 5,
          }}
        />
        <Typography
          variant="body1"
          textAlign="center"
          sx={{
            fontWeight: "light",
            fontSize: { xs: "0.5em", md: "1em" },
            pb: 2,
          }}
        >
          Live From Israel doesn't keep and/or use the payment information
          submitted through our website. After the purchase, you will receive an
          invoice for the email address you submitted to PayPal during your
          payment.
        </Typography>
      </Container>
    </Paper>
  );
};

export default DonationModalContent;
