import React from "react";
import { Avatar, Box, Button } from "@mui/material";
import { googleIcon } from "../../utils/publicImport";
import { SERVER_ROUTES } from "../../utils/contants";

const SocialMedia = () => {
  return (
    <Box
      display="flex"
      flexGrow={1}
      textAlign="center"
      justifyContent="space-evenly"
      alignItems="center"
      flexWrap="wrap"
    >
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        sx={{ minWidth: 80, minHeight: 60, textTransform: "capitalize" }}
        onClick={() => {
          window.open(
            process.env.REACT_APP_SERVER_URL + SERVER_ROUTES.USER.GOOGLE_LOGIN,
            "_self"
          );
        }}
      >
        <Avatar src={googleIcon} />
        &emsp; Continue with Google
      </Button>
    </Box>
  );
};

export default SocialMedia;
