import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, CircularProgress, IconButton } from "@mui/material";
import { DeleteForeverTwoTone } from "@mui/icons-material";
import InfoCellModal from "./InfoCellModal";

const getColumns = (
  data,
  handleEdit,
  handleDelete,
  showEdit,
  showDelete,
  title
) => {
  const columns = [];

  columns?.push({
    field: "Info",
    headerName: "",
    width: 60,
    renderCell: (cellValues) => {
      return <InfoCellModal title={title} myObject={cellValues.row} />;
    },
  });

  // if (showEdit) {
  //   columns?.push({
  //     field: "Edit",
  //     headerName: "",
  //     width: 60,
  //     renderCell: (cellValues) => {
  //       return (
  //         <IconButton
  //           color="warning"
  //           onClick={() => handleEdit(cellValues?.row)}
  //         >
  //           <EditTwoTone color="warning" />
  //         </IconButton>
  //       );
  //     },
  //   });
  // }

  if (data?.length > 0) {
    const dataObject = data?.[0];
    for (const dataObjectKey in dataObject) {
      const newObject = {
        field: dataObjectKey,
        headerName: dataObjectKey?.toUpperCase(),
        width: 200,
      };
      columns?.push(newObject);
    }
  }

  if (showDelete) {
    columns?.push({
      field: "Delete",
      headerName: "",
      width: 60,
      renderCell: (cellValues) => {
        return (
          <IconButton
            color="error"
            onClick={() => handleDelete(cellValues?.id)}
          >
            <DeleteForeverTwoTone color="error" />
          </IconButton>
        );
      },
    });
  }

  return columns;
};

const DataTable = ({
  data = [],
  handleEdit,
  handleDelete,
  showEdit = true,
  showDelete = true,
  isLoading,
  title,
}) => {
  return (
    <div style={{ width: "100%" }}>
      {isLoading ? (
        <Box
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: 5,
          }}
        >
          <CircularProgress size={100} />
        </Box>
      ) : (
        <DataGrid
          rows={data}
          getRowId={(object) => object?._id}
          columns={getColumns(
            data,
            handleEdit,
            handleDelete,
            showEdit,
            showDelete,
            title
          )}
          pageSize={10}
          rowsPerPageOptions={[10]}
          checkboxSelection={false}
          autoHeight
        />
      )}
    </div>
  );
};

export default DataTable;
