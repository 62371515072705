import React, { useEffect } from "react";
import { Alert, AlertTitle, Box } from "@mui/material";
import DataTable from "./DataTable";
import { useDispatch, useSelector } from "react-redux";
import { getJWTFromLocalStorage } from "../../utils/functions";
import { STATUS } from "../../utils/contants";
import {
  getAllUsers,
  selectAdminAllUsersState,
} from "../../redux/user/adminAllUsersSlice";

const Users = () => {
  const dispatch = useDispatch();

  const { adminAllUsersArray, adminAllUsersStatus, adminAllUsersError } =
    useSelector(selectAdminAllUsersState);

  useEffect(() => {
    const token = getJWTFromLocalStorage();
    if (adminAllUsersStatus === STATUS.IDLE && token) {
      dispatch(getAllUsers(token));
    }
  }, [dispatch, adminAllUsersStatus]);

  return (
    <Box my={5}>
      <h2>Users</h2>
      {adminAllUsersError && (
        <Alert severity="adminAllUsersError" sx={{ my: 2 }}>
          <AlertTitle>{adminAllUsersError}</AlertTitle>
        </Alert>
      )}
      <DataTable
        title={"Users"}
        data={adminAllUsersArray}
        showEdit={false}
        showDelete={false}
      />
    </Box>
  );
};

export default Users;
