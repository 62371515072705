import React from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Toolbar,
  useScrollTrigger,
} from "@mui/material";
import PropTypes from "prop-types";
import { Person, TouchApp } from "@mui/icons-material";
import { logo } from "../utils/publicImport";
import { ROUTES } from "../utils/contants";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { selectVerifyByEmailState } from "../redux/user/verifyByEmailSlice";
import { selectVerifyJWTState } from "../redux/user/verifyJWTSlice";
import useWindowDimensions from "../utils/useWindowDimensions";
import logoText from "../assets/logo-with-text-min.png";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const TopNavBar = (props) => {
  const navigate = useNavigate();
  const { verifyByEmailUser } = useSelector(selectVerifyByEmailState);
  const { verifyJWTUser } = useSelector(selectVerifyJWTState);

  const { width } = useWindowDimensions();

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar
          position="fixed"
          color="transparent"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "::WebkitBackdrop": "blur(5px)",
            backdropFilter: "blur(5px)",
          }}
        >
          <Container>
            <Toolbar sx={{ p: 0 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Button
                  variant="text"
                  color="primary"
                  sx={{
                    textTransform: "capitalize",
                  }}
                  onClick={() => navigate(ROUTES.HOME)}
                >
                  {width > 600 ? (
                    <Box
                      component="img"
                      maxWidth={300}
                      alt="Live From Israel logo-round"
                      src={logoText}
                    />
                  ) : (
                    <Avatar alt="Live From Israel logo-round" src={logo} />
                  )}
                </Button>
              </Box>
              <Button
                variant="contained"
                color="warning"
                sx={{
                  textTransform: "capitalize",
                  marginRight: 1,
                  fontSize: { xs: "small", md: "large" },
                }}
                onClick={() => navigate(ROUTES.DONATE)}
              >
                Donate &nbsp; <TouchApp />
              </Button>
              {verifyJWTUser || verifyByEmailUser ? (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { xs: "small", md: "large" },
                  }}
                  onClick={() => navigate(ROUTES.DASHBOARD)}
                >
                  Dashboard &nbsp; <Person />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "capitalize",
                    fontSize: { xs: "small", md: "large" },
                  }}
                  onClick={() => navigate(ROUTES.LOGIN)}
                >
                  log in
                </Button>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
    </>
  );
};

export default TopNavBar;
