import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import BlankPage from "../components/BlankPage";
import { RESET_PASSWORD_STEP, ROUTES, STATUS } from "../utils/contants";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  passwordResetStepTwo,
  selectPasswordResetState,
} from "../redux/passwordReset/passwordResetSlice";

const ResetPasswordStepTwo = () => {
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
    confirmPassword: "",
    showConfirmPassword: false,
  });

  const navigate = useNavigate();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const { step, token, completed, status, error } = useSelector(
    selectPasswordResetState
  );

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (step !== RESET_PASSWORD_STEP.VERIFY) {
      navigate(step);
    }
    if (completed) {
      navigate(ROUTES.LOGIN);
    }
  }, [step, completed, navigate]);

  return (
    <BlankPage>
      <Container maxWidth="xs">
        <Typography
          textAlign="center"
          py={5}
          sx={{
            fontWeight: "light",
            fontSize: { xs: "2em", md: "3em" },
            textTransform: "capitalize",
          }}
        >
          Reset Password
        </Typography>
        <Typography variant="body2">
          Please enter your new password, and confirm password.
        </Typography>
        <Box my={5}>
          <Divider />
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          textAlign="center"
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          flexWrap="wrap"
        >
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              <AlertTitle>{error}</AlertTitle>
            </Alert>
          )}
          <form
            onSubmit={(event) => {
              event.preventDefault();
              dispatch(
                passwordResetStepTwo({
                  password: values.password,
                  confirmPassword: values.confirmPassword,
                  token: token,
                })
              );
            }}
          >
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="outlined-adornment-confirm-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password"
                type={values.showConfirmPassword ? "text" : "password"}
                value={values.confirmPassword}
                onChange={handleChange("confirmPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownConfirmPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
              />
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: "capitalize", my: 5 }}
            >
              {status === STATUS.LOADING ? (
                <CircularProgress color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </Box>
      </Container>
    </BlankPage>
  );
};

export default ResetPasswordStepTwo;
