import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ROUTES, STATUS } from "../../utils/contants";
import axios from "axios";

const SERVER = process.env.REACT_APP_SERVER_URL;

export const authVerifyJWT = createAsyncThunk(
  SERVER + SERVER_ROUTES.USER.VERIFY_JWT,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.get(SERVER + SERVER_ROUTES.USER.VERIFY_JWT, {
        headers: {
          Authorization: `Bearer ${data}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  verifyJWTUser: "",
  verifyJWTStatus: STATUS.IDLE,
  verifyJWTError: "",
};

export const verifyJWTSlice = createSlice({
  name: "verifyJWT",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authVerifyJWT.pending, (state) => {
      state.verifyJWTStatus = STATUS.LOADING;
    });
    builder.addCase(authVerifyJWT.fulfilled, (state, action) => {
      state.verifyJWTStatus = STATUS.SUCCESS;
      state.verifyJWTUser = action.payload.data;
    });
    builder.addCase(authVerifyJWT.rejected, (state, action) => {
      state.verifyJWTStatus = STATUS.FAIL;
      state.verifyJWTError = action.payload;
    });
  },
});

export const selectVerifyJWTState = (state) => state.verifyJWT;

// export const {} = verifyJWTSlice.actions;

export default verifyJWTSlice.reducer;
