import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import BlankPage from "../components/BlankPage";
import { ROUTES, STATUS } from "../utils/contants";
import { useDispatch, useSelector } from "react-redux";
import { getJWTFromLocalStorage, localLogout } from "../utils/functions";
import {
  authEmailVerification,
  selectVerifyByEmailState,
} from "../redux/user/verifyByEmailSlice";

const VerifyByCode = () => {
  const [code, setCode] = useState("");

  const handleChange = (event) => {
    setCode(event.target.value);
  };

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { verifyByEmailUser, verifyByEmailStatus, verifyByEmailError } =
    useSelector(selectVerifyByEmailState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const token = getJWTFromLocalStorage();
    if (!token) {
      navigate(ROUTES.LOGIN);
    }
    if (verifyByEmailUser) {
      navigate(ROUTES.DASHBOARD);
    }
    if (verifyByEmailStatus === STATUS.SUCCESS) {
      navigate(ROUTES.USER);
    }
  }, [navigate, verifyByEmailUser, verifyByEmailStatus]);

  return (
    <BlankPage>
      <Container maxWidth="xs">
        <Typography
          textAlign="center"
          py={5}
          sx={{
            fontWeight: "light",
            fontSize: { xs: "2em", md: "3em" },
            textTransform: "capitalize",
          }}
        >
          Email Verification
        </Typography>
        <Typography variant="body2">
          Please enter the verification code sent to your email address.
        </Typography>
        <Box my={5}>
          <Divider />
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          textAlign="center"
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          flexWrap="wrap"
        >
          {verifyByEmailError && (
            <Alert severity="error" sx={{ my: 2 }}>
              <AlertTitle>{verifyByEmailError}</AlertTitle>
            </Alert>
          )}
          <form
            onSubmit={(event) => {
              event.preventDefault();
              const token = getJWTFromLocalStorage();
              if (token)
                dispatch(authEmailVerification({ code: code, token: token }));
            }}
          >
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="outlined-verificationCode">
                Verification code
              </InputLabel>
              <OutlinedInput
                id="outlined-verificationCode"
                type="text"
                value={code}
                onChange={handleChange}
                label="Verification code"
              />
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: "capitalize", my: 5 }}
            >
              {verifyByEmailStatus === STATUS.LOADING ? (
                <CircularProgress color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </Box>
        <Typography variant="body2" textAlign="center">
          Didn't get the email?
          <Button
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              localLogout();
              window.open(ROUTES.LOGIN, "_self");
            }}
          >
            Try a different user
          </Button>
        </Typography>
      </Container>
    </BlankPage>
  );
};

export default VerifyByCode;
