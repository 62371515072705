import React from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import {PlayCircleFilledTwoTone} from "@mui/icons-material";

const MediaPlayer = ({ link, title, subtitle }) => {
  return (
    <Paper elevation={6} sx={{ width: "100%", m: 2 }}>
      <Box p={2}>
        <ReactPlayer url={link} width="100%" light controls playIcon={<PlayCircleFilledTwoTone color='info' sx={{fontSize: '8em'}} />} />
      </Box>
      <Box>
        <Divider />
      </Box>
      <Box p={2}>
        <Typography
          textAlign="center"
          color="primary"
          sx={{
            fontWeight: "900",
            fontSize: { xs: "2em", md: "3.5em" },
            textAlign: "center",
            textTransform: "capitalize",
            margin: 0,
            padding: 0,
          }}
        >
          {title}
        </Typography>
        <Typography
          textAlign="center"
          color="primary"
          sx={{
            fontWeight: "light",
            fontSize: { xs: "1em", md: "1.5em" },
            textAlign: "center",
            textTransform: "capitalize",
            margin: 0,
            padding: 0,
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Paper>
  );
};

export default MediaPlayer;
