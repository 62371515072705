import React from "react";
import { Box } from "@mui/material";
import VideoWithDescription from "./VideoWithDescription";

const VideosDashboard = ({ videos }) => {
  return (
    <Box my={5}>
      {videos?.map((v) => {
        return (
          <VideoWithDescription
            key={v._id}
            location={v.location}
            description={v.description}
            url={v.url}
            img={v.img}
            date={v.date}
          />
        );
      })}
    </Box>
  );
};

export default VideosDashboard;
