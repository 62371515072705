import {
  mobileDashboard,
  mobileLogin,
  mobileVideo,
  DeadSea,
  Eilat,
  Haifa,
  Hermon,
  Jaffa,
  Jerusalem,
  Masada,
  TelAviv,
} from "./publicImport";
import { v4 as uuidv4 } from "uuid";

export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/sign-up",
  USER: "/user",
  VERIFY_BY_CODE: "/verify-by-code",
  DASHBOARD: "/dashboard",
  ADMIN: "/admin",
  DONATE: "/donate",
  PRIVACY_POLICY: "/privacy-policy",
  NOT_FOUND: "*",
  RESET_PASSWORD: {
    STEP_ONE: "/reset-password-step-one",
    VERIFY_BY_CODE: "/reset-password-verify-by-code",
    STEP_TWO: "/reset-password-step-two",
  },
};

export const LINKS = {
  FACEBOOK: "https://www.facebook.com/livefromisrael/",
  INSTAGRAM: "https://www.instagram.com/livefromisrael/",
  EMAIL: "mailto: livefromisrael@gmail.com",
  NEW_TAB: "_blank",
};
export const STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  FAIL: "fail",
};

export const RESET_PASSWORD_STEP = {
  ONE: "/reset-password-step-one",
  TWO: "/reset-password-step-two",
  VERIFY: "/reset-password-verify-by-code",
};

export const SERVER_ROUTES = {
  USER: {
    LOGIN: "/api/user/login",
    GOOGLE_LOGIN: "/api/user/login/google",
    GOOGLE_LOGOUT: "/api/user/logout",
    REGISTER: "/api/user/register",
    VERIFY_BY_CODE: "/api/user/verify-by-code",
    VERIFY_JWT: "/api/user/verify-jwt",
    VERIFY_JWT_ADMIN: "/api/user/verify-jwt-admin",
    RESET_PASSWORD_PART_ONE: "/api/user/reset-password-part-one",
    RESET_PASSWORD_PART_TWO: "/api/user/reset-password-part-two",
    INFO_ALL_USERS: "/api/user/info/all",
    LOGOUT: "/api/user/logout",
  },
  DONATION: {
    PAYPAL: "/api/donation/paypal",
    ALL_DONATIONS: "/api/donation/all",
  },
  TOURS: {
    CREATE: "/api/tour/create",
    ALL_TOURS: "/api/tour/all",
  },
};

export const videos = [
  {
    _id: uuidv4(),
    location: "Jerusalem",
    url: "https://livefromisrael.wistia.com/medias/zqrmjnwbrh",
    img: "https://drive.google.com/uc?id=1KuW-P8CiikrrB6W7uWcA-EXIZMYyqVVG",
    date: "February 2022",
    description:
      "Jerusalem ( Hebrew: יְרוּשָׁלַיִם Yerushaláyim) is a city in Western Asia. Situated on a plateau in the Judaean Mountains between the Mediterranean and the Dead Sea, it is one of the oldest cities in the world and is considered to be a holy city for the three major Abrahamic religions: Judaism, Christianity, and Islam.",
  },
  {
    _id: uuidv4(),
    location: "Masada",
    url: "https://livefromisrael.wistia.com/medias/mat4hp34m4",
    img: "https://drive.google.com/uc?id=1OudlbxExU7Qvqp27KejK9VXr270j9xTl",
    date: "October 2021",
    description:
      "Masada (Hebrew: מצדה metsada, fortress) is an ancient fortification in the Southern District of Israel situated on top of an isolated rock plateau, akin to a mesa. It is located on the eastern edge of the Judaean Desert, overlooking the Dead Sea 20 km (12 mi) east of Arad.",
  },
  {
    _id: uuidv4(),
    location: "Jaffa",
    url: "https://livefromisrael.wistia.com/medias/fia7zng4ig",
    img: "https://drive.google.com/uc?id=1dDPUlz9fWsA570UVxOaYhzUQZWqJlZgn",
    date: "March 2022",
    description:
      "Jaffa, in Hebrew Yafo (Hebrew: יָפוֹ, Yāfō) and also called Japho or Joppa, the southern and oldest part of Tel Aviv-Yafo, is an ancient port city in Israel. Jaffa is known for its association with the biblical stories of Jonah, Solomon, and Saint Peter.",
  },
  {
    _id: uuidv4(),
    location: "Tel-Aviv",
    url: "https://livefromisrael.wistia.com/medias/fia7zng4ig",
    date: "May 2022",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, hello",
  },
  {
    _id: uuidv4(),
    location: "Eilat",
    url: "https://livefromisrael.wistia.com/medias/zqrmjnwbrh",
    date: "December 2021",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, hello",
  },
  {
    _id: uuidv4(),
    location: "Dead-Sea",
    url: "https://livefromisrael.wistia.com/medias/mat4hp34m4",
    date: "July 2022",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, hello",
  },
];

export const steps = [
  {
    _id: uuidv4(),
    label: "Log in / Sign Up",
    description: `If you already Sign Up, log in and go to the dashboard. You can Sign Up with email and password, or with Google.`,
    image: mobileLogin,
  },
  {
    _id: uuidv4(),
    label: "Dashboard",
    description: `The dashboard contains all the tours available to you, to watch online in HD quality. To get to the dashboard click on the icon in the top right corner of the screen.`,
    image: mobileDashboard,
  },
  {
    _id: uuidv4(),
    label: "select a tour",
    description: `Press play and start watching. You can pause, skip forward or backward, and change the speed. There is a mirroring option so you can watch the tour on your TV screen.`,
    image: mobileVideo,
  },
];

export const images = [
  {
    _id: uuidv4(),
    label: "Jerusalem",
    imgPath: Jerusalem,
  },
  {
    _id: uuidv4(),
    label: "Tel Aviv",
    imgPath: TelAviv,
  },
  {
    _id: uuidv4(),
    label: "Jaffa",
    imgPath: Jaffa,
  },
  {
    _id: uuidv4(),
    label: "Masada",
    imgPath: Masada,
  },
  {
    _id: uuidv4(),
    label: "Haifa",
    imgPath: Haifa,
  },
  {
    _id: uuidv4(),
    label: "Dead Sea",
    imgPath: DeadSea,
  },
  {
    _id: uuidv4(),
    label: "Hermon",
    imgPath: Hermon,
  },
  {
    _id: uuidv4(),
    label: "Eilat",
    imgPath: Eilat,
  },
];
