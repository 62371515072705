export const getJWTFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("token"));
};

export const setJWTToLocalStorage = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
};

export const localLogout = () => {
  localStorage.clear();
};
