import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ROUTES, STATUS } from "../../utils/contants";
import axios from "axios";

const SERVER = process.env.REACT_APP_SERVER_URL;

export const getAllDonations = createAsyncThunk(
  SERVER + SERVER_ROUTES.DONATION.ALL_DONATIONS,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.get(SERVER + SERVER_ROUTES.DONATION.ALL_DONATIONS, {
        headers: {
          Authorization: `Bearer ${data}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  adminAllDonationsArray: [],
  adminAllDonationsStatus: STATUS.IDLE,
  adminAllDonationsError: "",
};

export const adminAllDonationsSlice = createSlice({
  name: "adminAllDonations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllDonations.pending, (state) => {
      state.adminAllDonationsStatus = STATUS.LOADING;
    });
    builder.addCase(getAllDonations.fulfilled, (state, action) => {
      state.adminAllDonationsStatus = STATUS.SUCCESS;
      state.adminAllDonationsArray = action.payload.data;
    });
    builder.addCase(getAllDonations.rejected, (state, action) => {
      state.adminAllDonationsStatus = STATUS.FAIL;
      state.adminAllDonationsError = action.payload;
    });
  },
});

export const selectAdminAllDonationsState = (state) => state.adminAllDonations;

// export const {} = adminAllDonationsSlice.actions;

export default adminAllDonationsSlice.reducer;
