import React from "react";
import { Box, CardMedia, Container, Paper, Typography } from "@mui/material";
import ImageCarousel from "./ImageCarousel";
import { RecordGuide } from "../../utils/publicImport";
import { images } from "../../utils/contants";

const SectionThree = () => {
  return (
    <Paper elevation={0} sx={{ background: "white", minHeight: "100vh" }}>
      <Container sx={{ paddingTop: "64px", textAlign: "center" }}>
        <Box sx={{ minHeight: 120 }} />
        <Typography
          textAlign="center"
          mb={5}
          sx={{
            fontWeight: "900",
            fontSize: { xs: "2em", md: "5em" },
            textTransform: "capitalize",
          }}
        >
          Real Tour. Real Guide.
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{
            fontWeight: "light",
            fontSize: { xs: "1.3em", md: "2em" },
            marginBottom: 5,
          }}
        >
          Every tour is ~60 minutes long. The guide is a professional tour guide
          that takes a route of the major landmarks in each location.
        </Typography>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{ fontWeight: "light", fontSize: { xs: "1.3em", md: "2em" } }}
        >
          You will walk together with the guide through the landmarks, and will
          pass through the amazing experience of each location.
        </Typography>
        <CardMedia
          component="img"
          src={RecordGuide}
          alt="Devices Live From Israel"
          sx={{
            marginX: "auto",
            marginY: 10,
          }}
        />
      </Container>
      <Box my={{ xs: 10, md: 20, lg: 30 }}>
        <ImageCarousel images={images} />
      </Box>
    </Paper>
  );
};

export default SectionThree;
