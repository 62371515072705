import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ROUTES, SERVER_ROUTES } from "../../utils/contants";
import { selectVerifyJWTAdminState } from "../../redux/user/verifyJWTAdminSlice";
import { localLogout } from "../../utils/functions";

const HeaderDashboard = ({ email }) => {
  const navigate = useNavigate();

  const { verifyJWTAdminUser } = useSelector(selectVerifyJWTAdminState);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        mb: 2,
        mt: 5,
      }}
    >
      <Box>
        <Typography
          textAlign="left"
          sx={{
            fontWeight: "Bold",
            fontSize: { xs: "1em", md: "2em" },
            textTransform: "capitalize",
          }}
        >
          Dashboard
        </Typography>
        <Typography
          textAlign="left"
          sx={{
            fontWeight: "light",
            fontSize: { xs: "1.3em", md: "2.5em" },
          }}
        >
          {email}
        </Typography>
      </Box>
      <Box sx={{ flex: "1 1 auto" }} />
      <Box>
        <Button
          color="error"
          sx={{ textTransform: "capitalize" }}
          onClick={() => {
            localLogout();
            window.open(
              process.env.REACT_APP_SERVER_URL +
                SERVER_ROUTES.USER.GOOGLE_LOGOUT,
              "_self"
            );
          }}
        >
          Log Out
        </Button>
        {verifyJWTAdminUser && (
          <Button
            color="secondary"
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              navigate(ROUTES.ADMIN);
            }}
          >
            Admin
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default HeaderDashboard;
