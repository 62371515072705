import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ROUTES, STATUS } from "../../utils/contants";
import axios from "axios";
import { setJWTToLocalStorage } from "../../utils/functions";

const SERVER = process.env.REACT_APP_SERVER_URL;

export const authLogin = createAsyncThunk(
  SERVER + SERVER_ROUTES.USER.LOGIN,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.post(SERVER + SERVER_ROUTES.USER.LOGIN, data);
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  loginToken: "",
  loginStatus: STATUS.IDLE,
  loginError: "",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setErrorFromUrl: (state, action) => {
      state.loginError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogin.pending, (state) => {
      state.loginStatus = STATUS.LOADING;
    });
    builder.addCase(authLogin.fulfilled, (state, action) => {
      state.loginStatus = STATUS.SUCCESS;
      const token = action.payload.data.token;
      setJWTToLocalStorage(token);
      state.loginToken = token;
    });
    builder.addCase(authLogin.rejected, (state, action) => {
      state.loginStatus = STATUS.FAIL;
      state.loginError = action.payload;
    });
  },
});

export const selectLoginState = (state) => state.login;

export const { setErrorFromUrl } = loginSlice.actions;

export default loginSlice.reducer;
