import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BlankPage from "../components/BlankPage";
import { Box, Container, Divider, Typography } from "@mui/material";
import SocialMedia from "../components/login/SocialMedia";
import EmailPassword from "../components/login/EmailPassword";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES, STATUS } from "../utils/contants";
import { useNavigate } from "react-router";
import { getJWTFromLocalStorage } from "../utils/functions";
import { selectLoginState } from "../redux/user/loginSlice";
import { selectRegisterState } from "../redux/user/registerSlice";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginStatus } = useSelector(selectLoginState);
  const { registerStatus } = useSelector(selectRegisterState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const token = getJWTFromLocalStorage();
    if (token) {
      navigate(ROUTES.USER);
    }
    if (loginStatus === STATUS.SUCCESS || registerStatus === STATUS.SUCCESS) {
      navigate(ROUTES.USER);
    }
  }, [navigate, dispatch, loginStatus, registerStatus]);

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta
          name="description"
          content="Login to Watch all the guided tours in one place."
        />
      </Helmet>
      <BlankPage>
        <Container maxWidth="xs">
          <Typography
            textAlign="center"
            py={5}
            sx={{
              fontWeight: "light",
              fontSize: { xs: "2em", md: "3em" },
              textTransform: "capitalize",
            }}
          >
            Log In
          </Typography>
          <SocialMedia />
          <Box my={5}>
            <Divider />
          </Box>
          <EmailPassword />
        </Container>
      </BlankPage>
    </>
  );
};

export default Login;
