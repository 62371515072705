import React from "react";
import {
  Box,
  CardMedia,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import ReactPlayer from "react-player";

const VideoWithDescription = ({ location, description, url, img, date }) => {
  return (
    <Paper
      elevation={6}
      sx={{ width: "100%", backgroundColor: "#0054a6", my: 2 }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        direction="row-reverse"
      >
        <Grid
          container
          item
          sm={7}
          justifyContent="center"
          alignItems="center"
          direction="row-reverse"
          px={2}
        >
          <Grid
            container
            item
            xs={9}
            justifyContent="center"
            alignItems="center"
            p={{ xs: 2, md: 4 }}
          >
            <Box>
              <Typography
                textAlign="center"
                sx={{
                  fontWeight: "500",
                  fontSize: { xs: "1.5em", md: "2.2em" },
                  color: "white",
                  textTransform: "capitalize",
                }}
              >
                {location}
              </Typography>
              <Typography
                textAlign="center"
                sx={{
                  fontWeight: "light",
                  fontSize: { xs: "0.5em", md: "1em" },
                  color: "white",
                }}
              >
                {date}
              </Typography>
              <Divider color="white" sx={{ my: 1 }} />
              <Typography
                textAlign="center"
                sx={{
                  fontWeight: "light",
                  fontSize: { xs: "0.7em", md: "1.2em" },
                  color: "white",
                }}
              >
                {description}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={3}
            justifyContent="center"
            alignItems="center"
          >
            <CardMedia
              component="img"
              src={img}
              alt={location}
              sx={{
                maxWidth: "100%",
                marginX: "auto",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          sm={5}
          justifyContent="center"
          alignItems="center"
          p={{ xs: 2, md: 4 }}
        >
          <ReactPlayer url={url} width="100%" controls />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default VideoWithDescription;
