import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { close } from "../../redux/donateModal/donateModalSlice";
import DonationModalContent from "./DonationModalContent";

const DonateModal = () => {
  const open = useSelector((state) => state.donateModal.open);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(close());
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      sx={{ borderRadius: 5 }}
    >
      <DialogContent sx={{ padding: 0, margin: 0 }}>
        <DonationModalContent />
      </DialogContent>
    </Dialog>
  );
};

export default DonateModal;
