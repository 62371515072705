import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ROUTES, STATUS } from "../../utils/contants";
import axios from "axios";

const SERVER = process.env.REACT_APP_SERVER_URL;

export const authVerifyJWTAdmin = createAsyncThunk(
  SERVER + SERVER_ROUTES.USER.VERIFY_JWT_ADMIN,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.get(SERVER + SERVER_ROUTES.USER.VERIFY_JWT_ADMIN, {
        headers: {
          Authorization: `Bearer ${data}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  verifyJWTAdminUser: false,
  verifyJWTAdminStatus: STATUS.IDLE,
  verifyJWTAdminError: "",
};

export const verifyJWTAdminSlice = createSlice({
  name: "verifyJWTAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authVerifyJWTAdmin.pending, (state) => {
      state.verifyJWTAdminStatus = STATUS.LOADING;
    });
    builder.addCase(authVerifyJWTAdmin.fulfilled, (state) => {
      state.verifyJWTAdminStatus = STATUS.SUCCESS;
      state.verifyJWTAdminUser = true;
    });
    builder.addCase(authVerifyJWTAdmin.rejected, (state, action) => {
      state.verifyJWTAdminStatus = STATUS.FAIL;
      state.verifyJWTAdminError = action.payload;
    });
  },
});

export const selectVerifyJWTAdminState = (state) => state.verifyJWTAdmin;

// export const {} = verifyJWTAdminSlice.actions;

export default verifyJWTAdminSlice.reducer;
