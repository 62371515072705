import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ROUTES, STATUS } from "../../utils/contants";
import axios from "axios";
import { setJWTToLocalStorage } from "../../utils/functions";

const SERVER = process.env.REACT_APP_SERVER_URL;

export const authRegister = createAsyncThunk(
  SERVER + SERVER_ROUTES.USER.REGISTER,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.post(SERVER + SERVER_ROUTES.USER.REGISTER, data);
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  registerToken: "",
  registerStatus: STATUS.IDLE,
  registerError: "",
};

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authRegister.pending, (state) => {
      state.registerStatus = STATUS.LOADING;
    });
    builder.addCase(authRegister.fulfilled, (state, action) => {
      state.registerStatus = STATUS.SUCCESS;
      const token = action.payload.data.token;
      setJWTToLocalStorage(token);
      state.registerToken = token;
    });
    builder.addCase(authRegister.rejected, (state, action) => {
      state.registerStatus = STATUS.FAIL;
      state.registerError = action.payload;
    });
  },
});

export const selectRegisterState = (state) => state.register;

// export const {} = registerSlice.actions;

export default registerSlice.reducer;
