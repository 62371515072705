import React, { useEffect } from "react";
import DataTable from "./DataTable";
import { Alert, AlertTitle, Box } from "@mui/material";
import NewTourModal from "./NewTourModal";
import { getJWTFromLocalStorage } from "../../utils/functions";
import { STATUS } from "../../utils/contants";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllTours,
  selectAllToursState,
} from "../../redux/tour/allToursSlice";

const Tours = () => {
  const handleDelete = (id) => {
    console.log(id);
  };

  const dispatch = useDispatch();

  const { allToursArray, allToursStatus, allToursError } =
    useSelector(selectAllToursState);

  useEffect(() => {
    const token = getJWTFromLocalStorage();
    if (allToursStatus === STATUS.IDLE && token) {
      dispatch(getAllTours(token));
    }
  }, [dispatch, allToursStatus]);

  return (
    <Box my={5}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <h2>Tours</h2>
        <NewTourModal />
      </Box>
      {allToursError && (
        <Alert severity="error" sx={{ my: 2 }}>
          <AlertTitle>{allToursError}</AlertTitle>
        </Alert>
      )}
      <DataTable
        title={"Tours"}
        data={allToursArray}
        handleDelete={handleDelete}
      />
    </Box>
  );
};

export default Tours;
