import React, { useState } from "react";
import {
  Box,
  Button,
  CardMedia,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

const VerticalStepper = ({ steps }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps?.map((step, index) => (
          <Step key={step?.label}>
            <StepLabel
              sx={{
                fontWeight: "900",
                textTransform: "capitalize",
              }}
            >
              {step?.label}
            </StepLabel>
            <StepContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "light",
                    fontSize: { xs: "1.3em", md: "2em" },
                    textAlign: "left",
                  }}
                >
                  {step?.description}
                </Typography>
                <CardMedia
                  component="img"
                  src={step?.image}
                  alt={step?.label}
                  sx={{
                    mx: "auto",
                    my: 2,
                    px: 2,
                    maxWidth: { xs: 100, sm: 200 },
                  }}
                />
              </Box>
              <Box my={2}>
                <div>
                  {index !== steps?.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      color="secondary"
                      size="large"
                      sx={{ textTransform: "capitalize", height: 60 }}
                    >
                      Next
                    </Button>
                  )}
                  {index !== 0 && (
                    <Button
                      onClick={handleBack}
                      color="secondary"
                      size="large"
                      sx={{ textTransform: "capitalize", height: 60 }}
                    >
                      Back
                    </Button>
                  )}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default VerticalStepper;
