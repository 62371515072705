import React from "react";
import {
  Box,
  Button,
  CardMedia,
  Container,
  Paper,
  Typography,
} from "@mui/material";

import { amber } from "@mui/material/colors";
import Lottie from "lottie-react";
import { PayPal } from "../../utils/publicImport";
import Donate from "../../assets/lottie/donate.json";
import { ROUTES } from "../../utils/contants";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { close } from "../../redux/donateModal/donateModalSlice";
import { TouchApp } from "@mui/icons-material";

const SectionSix = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Paper
      elevation={0}
      sx={{
        background: amber[400],
        minHeight: "100vh",
        borderRadius: 5,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <Container maxWidth="md">
        <Box sx={{ minHeight: 100 }} />
        <Typography
          textAlign="center"
          mb={5}
          sx={{
            fontWeight: "900",
            fontSize: { xs: "2.5em", md: "5em" },
          }}
        >
          Please. Donate.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexFlow: { xs: "wrap", md: "nowrap" },
            textAlign: "center",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginBottom: { xs: 2, sm: 10 },
          }}
        >
          <Box flexGrow={1}>
            <Typography
              variant="h4"
              textAlign="center"
              sx={{ fontWeight: "light", fontSize: { xs: "1.3em", md: "2em" } }}
            >
              We love doing guided tours, and we love sharing our fantastic work
              with you. Your donation helps us keep going, and creating more and
              more tours throughout the Holy Land of Israel.
            </Typography>
          </Box>
          <Box flexGrow={1} sx={{ minWidth: 300 }}>
            <Lottie animationData={Donate} style={{ height: 200 }} />
          </Box>
        </Box>
        <Button
          variant="contained"
          size="large"
          color="secondary"
          fullWidth
          sx={{
            mb: 5,
            minWidth: 200,
            textTransform: "capitalize",
            height: 60,
            fontSize: "1.3em",
          }}
          onClick={() => {
            dispatch(close());
            navigate(ROUTES.DONATE);
          }}
        >
          Donate <TouchApp />
        </Button>
        <Typography
          variant="h4"
          textAlign="center"
          sx={{ fontWeight: "light", fontSize: { xs: "1.3em", md: "2em" } }}
        >
          You can donate with Credit-Card (all brands), and PayPal. The payment
          the process is secure, and being handled by PayPal directly.
        </Typography>
        <CardMedia
          component="img"
          src={PayPal}
          alt="PayPal and credit cards"
          sx={{
            maxWidth: 200,
            marginX: "auto",
            marginY: 5,
          }}
        />
        <Typography
          variant="body1"
          textAlign="center"
          mb={5}
          sx={{ fontWeight: "light", fontSize: { xs: "0.5em", md: "1em" } }}
        >
          Live From Israel doesn't keep and/or use the payment information
          submitted through our website. After the purchase, you will receive an
          invoice for the email address you submitted to PayPal during your
          payment.
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        ></Box>
        <Box sx={{ minHeight: 100 }} />
      </Container>
    </Paper>
  );
};

export default SectionSix;
