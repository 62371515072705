import React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ROUTES, STATUS } from "../../utils/contants";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  authRegister,
  selectRegisterState,
} from "../../redux/user/registerSlice";

const EmailPasswordRegister = () => {
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
    confirmPassword: "",
    showConfirmPassword: false,
  });

  const navigate = useNavigate();

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  const { registerStatus, registerError } = useSelector(selectRegisterState);

  return (
    <Box
      display="flex"
      flexGrow={1}
      textAlign="center"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="center"
      flexWrap="wrap"
    >
      {registerError && (
        <Alert severity="error" sx={{ my: 2 }}>
          <AlertTitle>{registerError}</AlertTitle>
        </Alert>
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const userInfo = {
            email: values.email,
            password: values.password,
            confirmPassword: values.confirmPassword,
          };
          dispatch(authRegister(userInfo));
        }}
      >
        <FormControl fullWidth margin="normal" variant="outlined" required>
          <InputLabel htmlFor="outlined-email">Email</InputLabel>
          <OutlinedInput
            id="outlined-email"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
            label="Email"
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined" required>
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl fullWidth margin="normal" variant="outlined" required>
          <InputLabel htmlFor="outlined-adornment-confirm-password">
            Confirm Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-confirm-password"
            type={values.showConfirmPassword ? "text" : "password"}
            value={values.confirmPassword}
            onChange={handleChange("confirmPassword")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                  edge="end"
                >
                  {values.showConfirmPassword ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
          />
        </FormControl>
        <Button
          variant="contained"
          type="submit"
          sx={{ textTransform: "capitalize", my: 5 }}
        >
          {registerStatus === STATUS.LOADING ? (
            <CircularProgress color="inherit" />
          ) : (
            "Submit"
          )}
        </Button>
      </form>
      <Typography variant="body2">
        Already have a user?
        <Button
          sx={{ textTransform: "capitalize" }}
          onClick={() => navigate(ROUTES.LOGIN)}
        >
          Log In
        </Button>
      </Typography>
    </Box>
  );
};

export default EmailPasswordRegister;
