import React from "react";
import { Container } from "@mui/material";
// import Adsense from "./Adsense";

const BlankPage = ({ children }) => {
  return (
    <Container sx={{ paddingTop: "64px", minHeight: "100vh" }}>
      {children}
    </Container>
  );
};

export default BlankPage;
