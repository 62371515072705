import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  RESET_PASSWORD_STEP,
  SERVER_ROUTES,
  STATUS,
} from "../../utils/contants";
import axios from "axios";

const SERVER = process.env.REACT_APP_SERVER_URL;

export const passwordResetEmailVerification = createAsyncThunk(
  SERVER + SERVER_ROUTES.USER.VERIFY_BY_CODE,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.post(
        SERVER + SERVER_ROUTES.USER.VERIFY_BY_CODE,
        { code: data.code },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const passwordResetStepOne = createAsyncThunk(
  SERVER + SERVER_ROUTES.USER.RESET_PASSWORD_PART_ONE,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.post(
        SERVER + SERVER_ROUTES.USER.RESET_PASSWORD_PART_ONE,
        data
      );
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const passwordResetStepTwo = createAsyncThunk(
  SERVER + SERVER_ROUTES.USER.RESET_PASSWORD_PART_TWO,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.post(
        SERVER + SERVER_ROUTES.USER.RESET_PASSWORD_PART_TWO,
        { password: data.password, confirmPassword: data.confirmPassword },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  step: RESET_PASSWORD_STEP.ONE,
  token: "",
  isVerified: false,
  completed: false,
  status: STATUS.IDLE,
  error: "",
};

const passwordResetSlice = createSlice({
  name: "passwordReset",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(passwordResetStepOne.pending, (state) => {
      state.status = STATUS.LOADING;
      state.error = null;
    });
    builder.addCase(passwordResetStepOne.fulfilled, (state, action) => {
      state.status = STATUS.SUCCESS;
      state.step = RESET_PASSWORD_STEP.VERIFY;
      state.token = action.payload.data.token;
      state.error = null;
    });
    builder.addCase(passwordResetStepOne.rejected, (state, action) => {
      state.status = STATUS.FAIL;
      state.error = action.payload;
    });
    builder.addCase(passwordResetStepTwo.pending, (state) => {
      state.status = STATUS.LOADING;
      state.error = null;
    });
    builder.addCase(passwordResetStepTwo.fulfilled, (state) => {
      state.status = STATUS.SUCCESS;
      state.step = "";
      state.token = "";
      state.isVerified = false;
      state.completed = true;
      state.error = null;
    });
    builder.addCase(passwordResetStepTwo.rejected, (state, action) => {
      state.status = STATUS.FAIL;
      state.error = action.payload;
    });
    builder.addCase(passwordResetEmailVerification.pending, (state) => {
      state.status = STATUS.LOADING;
      state.error = null;
    });
    builder.addCase(passwordResetEmailVerification.fulfilled, (state) => {
      state.status = STATUS.SUCCESS;
      state.step = RESET_PASSWORD_STEP.TWO;
      state.isVerified = true;
      state.error = null;
    });
    builder.addCase(
      passwordResetEmailVerification.rejected,
      (state, action) => {
        state.status = STATUS.FAIL;
        state.error = action.payload;
      }
    );
  },
});

export const selectPasswordResetState = (state) => state.passwordReset;

// export const {} = passwordResetSlice.actions;

export default passwordResetSlice.reducer;
