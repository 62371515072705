import React, { useEffect } from "react";
import BlankPage from "../components/BlankPage";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { ROUTES, STATUS } from "../utils/contants";
import {
  getJWTFromLocalStorage,
  setJWTToLocalStorage,
} from "../utils/functions";
import { setErrorFromUrl } from "../redux/user/loginSlice";
import { selectVerifyByEmailState } from "../redux/user/verifyByEmailSlice";
import {
  authVerifyJWT,
  selectVerifyJWTState,
} from "../redux/user/verifyJWTSlice";

const User = () => {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { verifyByEmailUser } = useSelector(selectVerifyByEmailState);
  const { verifyJWTUser, verifyJWTStatus } = useSelector(selectVerifyJWTState);

  useEffect(() => {
    if (searchParams.get("token")) {
      setJWTToLocalStorage(searchParams.get("token"));
    }
    if (searchParams.get("error")) {
      dispatch(setErrorFromUrl(searchParams.get("error")));
      navigate(ROUTES.LOGIN);
    }
    const token = getJWTFromLocalStorage();
    if (
      !verifyJWTUser &&
      !verifyByEmailUser &&
      token &&
      verifyJWTStatus === STATUS.IDLE
    ) {
      dispatch(authVerifyJWT(token));
    }
    if (verifyJWTStatus === STATUS.FAIL) {
      navigate(ROUTES.VERIFY_BY_CODE);
    }
    if (verifyJWTUser || verifyByEmailUser) {
      navigate(ROUTES.DASHBOARD);
    }
    if (!token) {
      navigate(ROUTES.LOGIN);
    }
  }, [
    dispatch,
    navigate,
    searchParams,
    verifyJWTUser,
    verifyByEmailUser,
    verifyJWTStatus,
  ]);

  return (
    <BlankPage>
      <Box textAlign="center">
        <Box minHeight={120} />
        <CircularProgress />
      </Box>
    </BlankPage>
  );
};

export default User;
