import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ROUTES, STATUS } from "../../utils/contants";
import axios from "axios";

const SERVER = process.env.REACT_APP_SERVER_URL;

export const getAllUsers = createAsyncThunk(
  SERVER + SERVER_ROUTES.USER.INFO_ALL_USERS,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.get(SERVER + SERVER_ROUTES.USER.INFO_ALL_USERS, {
        headers: {
          Authorization: `Bearer ${data}`,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  adminAllUsersArray: [],
  adminAllUsersStatus: STATUS.IDLE,
  adminAllUsersError: "",
};

export const adminAllUsersSlice = createSlice({
  name: "adminAllUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.pending, (state) => {
      state.adminAllUsersStatus = STATUS.LOADING;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.adminAllUsersStatus = STATUS.SUCCESS;
      state.adminAllUsersArray = action.payload.data;
    });
    builder.addCase(getAllUsers.rejected, (state, action) => {
      state.adminAllUsersStatus = STATUS.FAIL;
      state.adminAllUsersError = action.payload;
    });
  },
});

export const selectAdminAllUsersState = (state) => state.adminAllUsers;

// export const {} = adminAllUsersSlice.actions;

export default adminAllUsersSlice.reducer;
