import React from "react";
import { Container, Paper, Typography } from "@mui/material";
import VerticalStepper from "./VerticalStepper";
import { steps } from "../../utils/contants";

const SectionFour = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        background: "white",
        minHeight: "100vh",
      }}
    >
      <Container maxWidth="md" sx={{ textAlign: "center" }}>
        <Typography
          textAlign="center"
          mb={5}
          sx={{
            fontWeight: "900",
            fontSize: { xs: "2.5em", md: "5em" },
            textTransform: "capitalize",
          }}
        >
          3 easy steps.
        </Typography>
        <VerticalStepper steps={steps} />
      </Container>
    </Paper>
  );
};

export default SectionFour;
