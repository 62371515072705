import { Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { red, green, amber } from "@mui/material/colors";

import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Admin from "./pages/Admin";
import NotFound from "./pages/NotFound";
import TopNavBar from "./components/TopNavBar";
import { ROUTES } from "./utils/contants";
import Donate from "./pages/Donate";
import Footer from "./components/Footer";
import DonateModal from "./components/donate/DonateModal";
import VerifyByCode from "./pages/VerifyByCode";
import ResetPasswordStepOne from "./pages/ResetPasswordStepOne";
import ResetPasswordVerifyByCode from "./pages/ResetPasswordVerifyByCode";
import ResetPasswordStepTwo from "./pages/ResetPasswordStepTwo";
import User from "./pages/User";
import { Helmet } from "react-helmet";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Adsense from "./components/Adsense";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0054a6",
    },
    secondary: {
      main: "#282828",
    },
    info: {
      main: "#00abee",
    },
    warning: {
      main: amber[400],
    },
    success: {
      main: green[300],
    },
    error: {
      main: red[400],
    },
    light: {
      main: "#ffffff",
    },
    google: {
      main: "#DB4437",
    },
    facebook: {
      main: "#4267B2",
    },
    twitter: {
      main: "#1DA1F2",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Live From Israel</title>
      </Helmet>
      <TopNavBar />
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.LOGIN} element={<Login />} />
        <Route path={ROUTES.REGISTER} element={<Register />} />
        <Route path={ROUTES.USER} element={<User />} />
        <Route path={ROUTES.VERIFY_BY_CODE} element={<VerifyByCode />} />
        <Route
          path={ROUTES.RESET_PASSWORD.STEP_ONE}
          element={<ResetPasswordStepOne />}
        />
        <Route
          path={ROUTES.RESET_PASSWORD.VERIFY_BY_CODE}
          element={<ResetPasswordVerifyByCode />}
        />
        <Route
          path={ROUTES.RESET_PASSWORD.STEP_TWO}
          element={<ResetPasswordStepTwo />}
        />
        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
        <Route path={ROUTES.ADMIN} element={<Admin />} />
        <Route path={ROUTES.DONATE} element={<Donate />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
      </Routes>
      <DonateModal />
      <Adsense />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
