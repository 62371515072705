import React from "react";
import { Grid } from "@mui/material";
import MediaPlayer from "./MediaPlayer";

const ThreeSampleVideos = () => {
  return (
    <>
      <Grid container justifyContent="space-around" alignItems="center" px={1}>
        <Grid
          container
          item
          md={4}
          justifyContent="space-around"
          alignItems="center"
        >
          <MediaPlayer
            link={"https://youtu.be/77pJLNFFeAg"}
            title={"Jerusalem"}
            subtitle={"Tower Of David"}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="space-around"
          alignItems="center"
        >
          <MediaPlayer
            link={"https://youtu.be/lCyH5IydT3I"}
            title={"Jerusalem"}
            subtitle={"Western Wall"}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="space-around"
          alignItems="center"
        >
          <MediaPlayer
            link={"https://youtu.be/J_VTxNDDHAg"}
            title={"Jerusalem"}
            subtitle={"King David's Tomb"}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ThreeSampleVideos;
