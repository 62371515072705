import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  counter: 0,
};

export const donateModalSlice = createSlice({
  name: "donateModal",
  initialState,
  reducers: {
    open: (state) => {
      state.open = true;
      state.counter += 1;
    },
    close: (state) => {
      state.open = false;
    },
  },
});

export const { open, close } = donateModalSlice.actions;

export default donateModalSlice.reducer;
