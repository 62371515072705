import React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";

const StepThree = ({ handleReset, paypalInfo }) => {
  const payerInfo = paypalInfo?.payer;
  const amountInfo = paypalInfo?.purchase_units?.find((obj) => obj?.amount);

  return (
    <>
      <Alert severity="success" sx={{ marginY: 5 }}>
        <AlertTitle>Payment process completed successfully</AlertTitle>
        <Divider />
        <Typography mt={2} variant="h6">
          Here is information about payment confirmation from PayPal:
        </Typography>
        <Typography variant="body1">
          Name: {payerInfo?.name?.given_name} {payerInfo?.name?.surname}
        </Typography>
        <Typography variant="body1">
          Email: {payerInfo?.email_address}
        </Typography>
        <Typography variant="body1">
          Amount: {amountInfo?.amount?.value}
        </Typography>
        <Typography variant="body1">
          Currency: {amountInfo?.amount?.currency_code}
        </Typography>
        <Typography variant="body1">
          Transaction ID: {paypalInfo?.id}
        </Typography>
        <Typography variant="body1">
          Transaction Status: {paypalInfo?.status}
        </Typography>
      </Alert>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          onClick={handleReset}
          size="large"
          sx={{ textTransform: "capitalize" }}
        >
          Make another donation
        </Button>
      </Box>
    </>
  );
};

export default StepThree;
