import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BlankPage from "../components/BlankPage";
import HeaderDashboard from "../components/dashboard/HeaderDashboard";
import { Divider } from "@mui/material";
import VideosDashboard from "../components/dashboard/VideosDashboard";
import { useDispatch, useSelector } from "react-redux";
import { open } from "../redux/donateModal/donateModalSlice";
import { ROUTES, STATUS } from "../utils/contants";
import { useNavigate } from "react-router";
import { selectVerifyByEmailState } from "../redux/user/verifyByEmailSlice";
import { selectVerifyJWTState } from "../redux/user/verifyJWTSlice";
import { getAllTours, selectAllToursState } from "../redux/tour/allToursSlice";
import { getJWTFromLocalStorage } from "../utils/functions";
import {
  authVerifyJWTAdmin,
  selectVerifyJWTAdminState,
} from "../redux/user/verifyJWTAdminSlice";

const Dashboard = () => {
  const counter = useSelector((state) => state.donateModal.counter);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { allToursArray, allToursStatus } = useSelector(selectAllToursState);
  const { verifyJWTAdminStatus } = useSelector(selectVerifyJWTAdminState);

  const { verifyByEmailUser } = useSelector(selectVerifyByEmailState);
  const { verifyJWTUser } = useSelector(selectVerifyJWTState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (counter === 0) {
      dispatch(open());
    }
    const token = getJWTFromLocalStorage();
    if (!token) {
      navigate(ROUTES.USER);
    }
    if (!(verifyJWTUser || verifyByEmailUser)) {
      navigate(ROUTES.USER);
    }
    if (allToursStatus === STATUS.IDLE && token) {
      dispatch(getAllTours(token));
    }
    if (verifyJWTAdminStatus === STATUS.IDLE && token) {
      dispatch(authVerifyJWTAdmin(token));
    }
  }, [
    dispatch,
    counter,
    navigate,
    allToursStatus,
    verifyJWTAdminStatus,
    verifyJWTUser,
    verifyByEmailUser,
  ]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta
          name="description"
          content="Watch all the guided tours in one place."
        />
      </Helmet>
      <BlankPage>
        <HeaderDashboard
          email={verifyJWTUser?.email || verifyByEmailUser?.email}
        />
        <Divider />
        <VideosDashboard videos={allToursArray} />
      </BlankPage>
    </>
  );
};

export default Dashboard;
