const urlFromImageId = (id) =>
  `https://drive.google.com/thumbnail?id=${id}&sz=w2000`;

export const logo = urlFromImageId("1rlhbOx8LqhdW91D3-tVrtzfr9SSXctGA");
export const googleIcon = urlFromImageId("1s0rcRyFotJEnxtd89fTZ0tCH7Uno_9r1");
export const RecordGuide = urlFromImageId("1tfAKx1YQ-avLD5AawJyaPp8x7G7mFKC3");
export const Jerusalem = urlFromImageId("1y51ctr6hs7deFD6CcEGWkvrKDSJeJhpy");
export const TelAviv = urlFromImageId("1VPb70W9U0BVGWjS-UPc7bray9nNPyuhA");
export const Jaffa = urlFromImageId("1n1Yo9gPaJP5AgohOlhocWWHwlvCQ3rnV");
export const Masada = urlFromImageId("1idP9npH_S5Z2U27Id3GLeBu6t4Mdn97g");
export const Haifa = urlFromImageId("1AdJSjTEFYTsqJlj3nCks4ZjTfc-RWjKZ");
export const DeadSea = urlFromImageId("1oczWPtOPFGGpykAHXz-FsaW1FewLUvSQ");
export const Hermon = urlFromImageId("1WMWh29OvcfQVi_8OdE50Lf3CRZi_xISl");
export const Eilat = urlFromImageId("15G7GExZtkaZzmXmQ3X9i-THxNR6vAxjz");
export const PayPal = urlFromImageId("1wqgoHcUGuKPl00dAGLhXXgzyxdX0bxlF");
export const Devices = urlFromImageId("1z0aR1D0h1YJymsN5wmpoShPZGCvTq3XQ");
export const dashboardImage = urlFromImageId(
  "1aI3TUtDavW5MMVbUcwCqDho0d7Q3NoDy"
);
export const mobileDashboard = urlFromImageId(
  "1asEZjDL6_qthIO6MVRoLoBZ4KGzOsFxA"
);
export const mobileLogin = urlFromImageId("1pTo7eiiSGq8jeylTP8rLK4vWT3JRCsIT");
export const mobileVideo = urlFromImageId("1ZigsuKN9TFwV7RytHOMFaLuME6E2Tbru");

// import logo from "../assets/images/logo-circle-min.png";
// import googleIcon from "../assets/images/google-icon-min.png";
// import RecordGuide from "../assets/images/record-guide-min.png";
// import Jerusalem from "../assets/images/jerusalem-min.jpg";
// import TelAviv from "../assets/images/telaviv-min.jpg";
// import Jaffa from "../assets/images/jaffa-min.jpg";
// import Masada from "../assets/images/masada-min.jpg";
// import Haifa from "../assets/images/haifa-min.jpg";
// import DeadSea from "../assets/images/deadsea-min.jpg";
// import Hermon from "../assets/images/hermon-min.jpg";
// import Eilat from "../assets/images/eilat-min.jpg";
// import PayPal from "../assets/images/paypal-min.png";
// import Devices from "../assets/images/devices-min.png";
// import dashboardImage from "../assets/images/dashboard-min.png";
// import mobileDashboard from "../assets/images/mobile-stepper-dashboard-min.png";
// import mobileLogin from "../assets/images/mobile-stepper-login-min.png";
// import mobileVideo from "../assets/images/mobile-stepper-video-min.png";
//
// export {
//     logo,
//     Devices,
//     Eilat,
//     Hermon,
//     DeadSea,
//     Masada,
//     Jaffa,
//     TelAviv,
//     dashboardImage,
//     Haifa,
//     mobileDashboard,
//     mobileLogin,
//     mobileVideo,
//     googleIcon,
//     PayPal,
//     Jerusalem,
//     RecordGuide,
// };
