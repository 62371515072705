import React from "react";
import {
  Box,
  Button,
  // FormControl,
  Grid,
  // InputAdornment,
  // InputLabel,
  // OutlinedInput,
  Typography,
} from "@mui/material";
import { ArrowRight } from "@mui/icons-material";

const amounts = ["2", "5", "10", "20"];

const StepOne = ({ handleNext, amount, setAmount }) => {
  return (
    <>
      <Typography mt={5} variant="body1" textAlign="center">
        Please select the amount for donation:
      </Typography>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        spacing={2}
        px={2}
        py={5}
      >
        {amounts.map((a) => {
          return (
            <Grid
              key={a}
              container
              item
              xs={6}
              md={3}
              justifyContent="space-around"
              alignItems="center"
            >
              <Button
                variant={amount === a ? "contained" : "outlined"}
                fullWidth
                size="large"
                sx={{ height: 60 }}
                onClick={() => setAmount(a)}
              >
                {" $ " + a}
              </Button>
            </Grid>
          );
        })}
        {/*<Grid*/}
        {/*  container*/}
        {/*  item*/}
        {/*  xs={12}*/}
        {/*  justifyContent="space-around"*/}
        {/*  alignItems="center"*/}
        {/*>*/}
        {/*  <FormControl fullWidth margin="normal" variant="outlined" required>*/}
        {/*    <InputLabel htmlFor="outlined-amount">Amount</InputLabel>*/}
        {/*    <OutlinedInput*/}
        {/*      id="outlined-amount"*/}
        {/*      type="number"*/}
        {/*      inputProps={{ min: 0 }}*/}
        {/*      value={amount}*/}
        {/*      sx={{ fontSize: " 1.5em" }}*/}
        {/*      startAdornment={*/}
        {/*        <InputAdornment position="start">*/}
        {/*          <AttachMoney />*/}
        {/*        </InputAdornment>*/}
        {/*      }*/}
        {/*      onChange={(event) => setAmount(event.target.value)}*/}
        {/*      label="Amount"*/}
        {/*    />*/}
        {/*  </FormControl>*/}
        {/*</Grid>*/}
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          onClick={handleNext}
          disabled={!amount}
          size="large"
          sx={{ textTransform: "capitalize" }}
        >
          Continue to payment <ArrowRight />
        </Button>
      </Box>
    </>
  );
};

export default StepOne;
