import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import BlankPage from "../components/BlankPage";
import { RESET_PASSWORD_STEP, STATUS } from "../utils/contants";
import { useDispatch, useSelector } from "react-redux";
import {
  passwordResetStepOne,
  selectPasswordResetState,
} from "../redux/passwordReset/passwordResetSlice";

const ResetPasswordStepOne = () => {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const { step, token, status, error } = useSelector(selectPasswordResetState);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (step !== RESET_PASSWORD_STEP.ONE) {
      navigate(step);
    }
    if (token) {
      navigate(RESET_PASSWORD_STEP.VERIFY);
    }
  }, [step, token, navigate]);

  return (
    <BlankPage>
      <Container maxWidth="xs">
        <Typography
          textAlign="center"
          py={5}
          sx={{
            fontWeight: "light",
            fontSize: { xs: "2em", md: "3em" },
            textTransform: "capitalize",
          }}
        >
          Your Email
        </Typography>
        <Typography variant="body2">
          Please enter your email address. If you are an existing user, you will
          receive a verification code to the email address you entered.
        </Typography>
        <Box my={5}>
          <Divider />
        </Box>
        <Box
          display="flex"
          flexGrow={1}
          textAlign="center"
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
          flexWrap="wrap"
        >
          {error && (
            <Alert severity="error" sx={{ my: 2 }}>
              <AlertTitle>{error}</AlertTitle>
            </Alert>
          )}
          <form
            onSubmit={(event) => {
              event.preventDefault();
              dispatch(passwordResetStepOne({ email: email }));
            }}
          >
            <FormControl fullWidth margin="normal" variant="outlined" required>
              <InputLabel htmlFor="outlined-email">email</InputLabel>
              <OutlinedInput
                id="outlined-email"
                type="email"
                value={email}
                onChange={handleChange}
                label="email"
              />
            </FormControl>
            <Button
              variant="contained"
              type="submit"
              sx={{ textTransform: "capitalize", my: 5 }}
            >
              {status === STATUS.LOADING ? (
                <CircularProgress color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </Box>
      </Container>
    </BlankPage>
  );
};

export default ResetPasswordStepOne;
