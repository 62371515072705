import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { ArrowLeft } from "@mui/icons-material";
import { PayPalButton } from "react-paypal-button-v2";

const StepTwo = ({ handleBack, amount, handleNext, handlePaypalInfo }) => {
  const [sdkReady, setSdkReady] = useState(false);

  useEffect(() => {
    const addPayPalScript = async () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!window.paypal) {
      addPayPalScript();
    } else {
      setSdkReady(true);
    }
  }, []);
  const successPaymentHandler = (paymentRes) => {
    handlePaypalInfo(paymentRes);
    handleNext();
  };
  return (
    <Box my={2}>
      <Paper sx={{ backgroundColor: "#0054a6", py: 2 }}>
        <Typography
          variant="h6"
          textAlign="center"
          sx={{ fontWeight: "light", color: "white" }}
        >
          You selected donation amount of:
        </Typography>
        <Typography
          my={1}
          variant="h2"
          textAlign="center"
          sx={{ color: "white", fontWeight: 900 }}
        >
          $ {amount}
        </Typography>
      </Paper>

      <Typography mt={2} variant="body2" textAlign="center">
        You can pay with PayPal or with credit card.
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          flexWrap: "wrap",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          my: 5,
        }}
      >
        {sdkReady ? (
          <PayPalButton
            amount={amount}
            onSuccess={successPaymentHandler}
            shippingPreference="NO_SHIPPING"
          />
        ) : (
          <CircularProgress size={100} />
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <Button
          onClick={handleBack}
          size="large"
          sx={{ textTransform: "capitalize" }}
        >
          <ArrowLeft />
          Back
        </Button>
        <Box sx={{ flex: "1 1 auto" }} />
      </Box>
    </Box>
  );
};

export default StepTwo;
