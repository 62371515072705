import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BlankPage from "../components/BlankPage";
import { Box, Container, Divider, Typography } from "@mui/material";
import SocialMedia from "../components/login/SocialMedia";
import EmailPasswordRegister from "../components/login/EmailPasswordRegister";
import { useDispatch } from "react-redux";
import { ROUTES } from "../utils/contants";
import { useNavigate } from "react-router";
import { getJWTFromLocalStorage } from "../utils/functions";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const token = getJWTFromLocalStorage();
    if (token) {
      navigate(ROUTES.USER);
    }
  }, [navigate, dispatch]);
  return (
    <>
      <Helmet>
        <title>Sign Up</title>
        <meta
          name="description"
          content="Sign Up to Watch all the guided tours in one place."
        />
      </Helmet>
      <BlankPage>
        <Container maxWidth="xs">
          <Typography
            textAlign="center"
            py={5}
            sx={{
              fontWeight: "light",
              fontSize: { xs: "2em", md: "3em" },
              textTransform: "capitalize",
            }}
          >
            Sign Up
          </Typography>
          <SocialMedia />
          <Box my={5}>
            <Divider />
          </Box>
          <EmailPasswordRegister />
        </Container>
      </BlankPage>
    </>
  );
};

export default Register;
