import React, { useState } from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepOne from "./StepOne";
import axios from "axios";
import { SERVER_ROUTES } from "../../utils/contants";

const steps = ["Amount", "Payment", "Confirmation"];

const SERVER = process.env.REACT_APP_SERVER_URL;

const postPaypalDonation = async (paypalResponse) => {
  try {
    await axios.post(SERVER + SERVER_ROUTES.DONATION.PAYPAL, {
      paypal: paypalResponse,
    });
  } catch (e) {
    console.log(e);
  }
};

const StepsContainer = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [amount, setAmount] = useState("");
  const [paypalInfo, setPayPalInfo] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setAmount("");
  };

  const handleReset = () => {
    setActiveStep(0);
    setAmount("");
  };

  const handleSetAmount = (amount) => {
    setAmount(amount);
  };

  const handlePaypalInfo = (object) => {
    postPaypalDonation(object);
    setPayPalInfo(object);
  };

  const showStep = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <StepOne
            handleNext={handleNext}
            amount={amount}
            setAmount={handleSetAmount}
          />
        );
      case 1:
        return (
          <StepTwo
            handleNext={handleNext}
            handleBack={handleBack}
            amount={amount}
            handlePaypalInfo={handlePaypalInfo}
          />
        );
      case steps?.length - 1:
        return <StepThree handleReset={handleReset} paypalInfo={paypalInfo} />;
      default:
        <></>;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {showStep(activeStep)}
    </Box>
  );
};

export default StepsContainer;
