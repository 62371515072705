import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SERVER_ROUTES, STATUS } from "../../utils/contants";
import axios from "axios";

const SERVER = process.env.REACT_APP_SERVER_URL;

export const authEmailVerification = createAsyncThunk(
  SERVER + SERVER_ROUTES.USER.VERIFY_BY_CODE,
  async (data, { rejectWithValue }) => {
    try {
      return await axios.post(
        SERVER + SERVER_ROUTES.USER.VERIFY_BY_CODE,
        { code: data.code },
        {
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      );
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  verifyByEmailUser: "",
  verifyByEmailStatus: STATUS.IDLE,
  verifyByEmailError: "",
};

export const verifyByEmailSlice = createSlice({
  name: "verifyByEmail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authEmailVerification.pending, (state) => {
      state.verifyByEmailStatus = STATUS.LOADING;
    });
    builder.addCase(authEmailVerification.fulfilled, (state, action) => {
      state.verifyByEmailStatus = STATUS.SUCCESS;
      state.verifyByEmailUser = action.payload.data;
    });
    builder.addCase(authEmailVerification.rejected, (state, action) => {
      state.verifyByEmailStatus = STATUS.FAIL;
      state.verifyByEmailError = action.payload;
    });
  },
});

export const selectVerifyByEmailState = (state) => state.verifyByEmail;

// export const {} = verifyByEmailSlice.actions;

export default verifyByEmailSlice.reducer;
